import {
  parseLinkURL,
  parseTextField,
} from "../../utils/helper-functions.utils";

class Contact {
  public contactName: string | null;
  public contactDetails: Array<string> | null;
  constructor(reporting_contact_info: Array<string>) {
    if (reporting_contact_info.length <= 0) return;
    this.contactName = reporting_contact_info[0];
    this.contactDetails = reporting_contact_info.slice(
      1,
      reporting_contact_info.length
    );
  }
}
export type TContact = Contact;

class Licensing {
  public isRequired: boolean | null;
  public licensingTitle: "No Permit" | "Permit Required" | null;
  public licensingDetails: Array<string> | null;
  public lisceningLink: string | null;
  constructor(
    permit_license_required: Array<string>,
    statePermitLicenseLink?: string
  ) {
    if (!permit_license_required || permit_license_required.length === 0)
      return;
    const isRequired: boolean = !["no", "none", "not"].some((x) =>
      permit_license_required[0].toLowerCase().trim().includes(x)
    );
    this.isRequired = isRequired;
    this.licensingTitle = !isRequired ? "No Permit" : "Permit Required";
    this.licensingDetails =
      permit_license_required.length > 1
        ? permit_license_required.slice(1, permit_license_required.length)
        : null;
    this.lisceningLink =
      statePermitLicenseLink && statePermitLicenseLink.length > 0
        ? statePermitLicenseLink
        : null;
  }
}
export type TLicensing = Licensing;

class Restriction {
  public text: string;
  public strongType: boolean;
}
export type TRestriction = Restriction;

class TextField {
  public text: string;
  public type: string;
}
export type TTextField = TextField;

class LinkAddress {
  public url: string;
  public text: string;
}
export type TLinkAddress = LinkAddress;

export class ShedPanelDocument {
  public shedPanelId: string;
  public title: string;
  public subtitle: string;
  public openingDay: any;
  public quickLook: Array<string>;
  public helpfulTips: Array<string>;
  public usefulLayersAndTools: Array<string> | null;
  public percentageOfPublicLand: string;
  public percentageOfWildernessAreas: string;
  public percentageOfRoadlessAreas: string;
  public liscensingRequirements: TLicensing;
  public generalRestrictions: Array<string> | null;
  public seasonalRestrictions: Array<TRestriction> | null;
  public deadheadRequirementsReporting: Array<string>;
  public reportingContactInfo: Array<TContact>;
  public stateWebsiteRules: string;
  public shedBuyers: string;
  public disclosure: string;

  constructor(data: any) {
    this.shedPanelId = data.shed_panel_id;
    this.title = parseTextField(data.title);
    this.subtitle = parseTextField(data.subtitle);
    this.openingDay = parseTextField(data.opening_day);
    this.quickLook =
      data.quick_look && data.quick_look.length > 0
        ? data.quick_look.map((x) => parseTextField(x))
        : null;
    this.helpfulTips =
      data.helpful_tips && data.helpful_tips.length > 0
        ? data.helpful_tips
            .filter((x) => x.text && x.text.length > 0)
            .map((x) => parseTextField(x))
            .filter((x) => x != null)
        : null;
    this.usefulLayersAndTools =
      data.useful_layers_and_tools && data.useful_layers_and_tools.length > 0
        ? Array.from(
            data.useful_layers_and_tools
              .filter((x) => x.text && x.text.length > 0)
              .map((x) => parseTextField(x))
              .filter((x) => x != null)
          )
        : null;
    this.percentageOfPublicLand = parseTextField(
      data.percentage_of_public_land
    );
    this.percentageOfWildernessAreas = parseTextField(
      data.percentage_of_wilderness
    );
    this.percentageOfRoadlessAreas = parseTextField(
      data.percentage_of_roadless_area
    );
    this.liscensingRequirements = this.parseLicensing(
      data.permit_license_required,
      data.state_permit_license_link
    );
    this.generalRestrictions =
      data.general_restrictions && data.general_restrictions.length > 0
        ? Array.from(
            data.general_restrictions
              .filter((x) => x.text && x.text.length > 0)
              .map((x) => parseTextField(x))
              .filter((x) => x != null)
          )
        : null;
    this.seasonalRestrictions =
      data.seasonal_restrictions && data.seasonal_restrictions.length > 0
        ? Array.from(
            data.seasonal_restrictions
              .map((x) => {
                let restriction: TRestriction = new Restriction();
                restriction.text = parseTextField(x);
                restriction.strongType =
                  x.spans && x.spans.length > 0 && x.spans[0].type === "strong";
                return restriction;
              })
              .filter((x) => x != null && x.text !== null && x.text.length > 0)
          )
        : null;
    this.deadheadRequirementsReporting =
      data.deadhead_requirements___reporting &&
      data.deadhead_requirements___reporting.length > 0
        ? Array.from(
            data.deadhead_requirements___reporting
              .filter((x) => x.text && x.text.length > 0)
              .map((x) => parseTextField(x))
              .filter((x) => x != null)
          )
        : null;
    this.reportingContactInfo = this.parseContacts(data.reporting_contact_info);
    this.stateWebsiteRules = parseLinkURL(data.state_website_rules);
    this.shedBuyers = parseTextField(data.shed_buyers);
    this.disclosure = parseTextField(data.disclosure);
  }
  private parseContacts = (reporting_contact_info): Array<TContact | null> =>
    reporting_contact_info && reporting_contact_info.length > 0
      ? reporting_contact_info
          .map((contact_info_item) => {
            const parsedContactStrings: Array<string> = parseTextField(
              contact_info_item
            ).split(/\n/);
            return parsedContactStrings.length <= 1
              ? null
              : new Contact(parsedContactStrings);
          })
          .filter((x) => x !== null)
      : null;

  private parseLicensing = (
    permit_license_required,
    state_permit_license_link
  ): TLicensing | null =>
    new Licensing(
      permit_license_required && permit_license_required.length > 0
        ? permit_license_required
            .map((x) => parseTextField(x))
            .filter((x) => x !== null)
        : null,
      parseLinkURL(state_permit_license_link)
    );
}
export type TShedPanelDocument = ShedPanelDocument;
