/******************************(shed-panel-page.template.tsx)*******************************/
import { UnitResearchPageProps } from "../schema/models/unit-research-page.model";
import { graphql } from "gatsby";
import { ShedPanelDocument } from "../schema/models/shed-panel-document.model";

import React from "react";
import UnitResearchPage from "../components/unit-research-page/unit-research-page.component"

export const ShedPanelPageTemplate = ({ data }) => {
  const ShedPanelPageDocument = new ShedPanelDocument(
    data.prismicShedpanel.dataRaw
  );
  return (
    <UnitResearchPage
      {...new UnitResearchPageProps(null, ShedPanelPageDocument)}
    />
  );
};
export const query = graphql`
  query($id: String!) {
    prismicShedpanel(id: { eq: $id }) {
      dataRaw
    }
  }
`;
export default ShedPanelPageTemplate;
